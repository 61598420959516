import enLocale from "element-ui/lib/locale/lang/en";
export default {
  lang: "English",
  // 菜单
  menu: {
    souye: "souye", //媒体
    data: "data", //数据
    docking: "docking", //对接
  },
  footer: {
    footerTopA: "About us",
    footerTopB: "statement of copyright",
    footerTopC: "Business Introduction",
    footerTopD: "Contact us",
    footerLeftA:
      "The information provided by this system is for research purposes only and should not be used as clinical diagnosis, medication, or medical evidence.",
    footerLeftB:
      "This system is jointly developed by Shandong Soso Traditional Chinese Medicine Information Technology Co., Ltd., in collaboration with the China Academy of Chinese Medical Sciences and Shandong University of Traditional Chinese Medicine.",
    footerLeftC:
      "Shandong ICP No. 13012670-2 Copyright ◎ 2017-2025 IMEDBOOKS All Rights Reserved ",
    footerRightA: "Official Account of IMEDBOOKS",
    footerRightB: "Video Account of IMEDBOOKS",
    footerRightC: "TikTok of IMEDBOOKS",
  },
  introduction: {
    navList: [
      {
        name: "Traditional Chinese Medicine Advantageous Disease Data Organization Service",
      },
      {
        name: "Retrieval Service of Traditional Chinese Medicine Classical Literature Documents",
      },
      {
        name: "Service for Inheritance of Famous Traditional Chinese Medicine Experts",
      },
      {
        name: "Service for Undertaking Traditional Chinese Medicine Related Conferences",
      },
      {
        name: "Construction of Traditional Chinese Medicine Classical Literature Information Platform",
      },
      {
        name: "Customized Organization of Traditional Chinese Medicine Literature and Data",
      },
      {
        name: "Organization and Hosting of Traditional Chinese Medicine Knowledge Contests",
      },
      {
        name: "More",
      },
    ],
  },
  // 中医圈
  ChineseMedicineCircle: {
    title: "IMEDBOOKS community",
    gengduo: "Click to see more",
    search: "Post a new topic",
  },
  // 活动专栏
  Activitycolumn: {
    title: "Event column",
    gengduo: "More",
  },
  // 热门榜单
  HotListView: {
    day: "day",
    top: "Pinned",
    title: "Popular ranking",
    titleD: "User learning ranking",
    list: [
      {
        name: "Top rankings for reading classics",
      },
      {
        name: "Top rankings for Chinese medicine",
      },
      {
        name: "Top rankings for prescriptions",
      },
    ],
  },
  // 学术指南
  Academic: {
    title: "Academic guide",
    gengduo: "More",
    navList: [
      {
        name: "Policy documents",
      },
      {
        name: "Diagnosis and treatment guidelines",
      },
      {
        name: "Medical news",
      },
      {
        name: "Comprehensive dynamic",
      },
      {
        name: "Classical prescriptions for study and application",
      },
      {
        name: "Recommended classics",
      },
    ],
  },
  // 方药统计
  Prescription: {
    title: "Prescription statistics",
    titleB: "Intelligent Prescription",
    contentA: "Prescription statistics by disease",
    contentB: "Statistics of materia medica classics",
    contentC:
      "Intelligent prescription tailored to the symptoms, providing guidance on traditional Chinese medicine prescriptions for treating diseases and ailments.",
  },
  // 首页顶部
  home: {
    tishi: "Malaria",
    jinru: "enter",
    guanbi: "Close",
    geren: "Personal center",
    tuichu: "Logout",
    search: {
      hotsearch: "Hot search terms",
      Advanced: "Advanced search",
      del: "Delete",
      prepend: "Search criteria",
      text: "Please enter the content you want to search",
      all: "Select all",
      alldel: "Clear all search history",
    },
    top: {
      welcome: "Welcome",
      yewu: "Business Introduction",
      jiaocheng: "user guide",
      zhixun: "Consultation Hotline",
    },
    navlist: {
      shouye: "Home page",
      shujuku: "Database",
      xueshu: "Academic guide",
      fangyao: "Prescription statistics",
      zhihui: "Intelligent Prescription",
      zhongyi: "IMEDBOOKS community",
      remen: "Popular ranking",
      huodong: "Event column",
      chongzhi: "Recharge center",
      chongzhiA: "Recharge membership",
      chongzhiB: "Recharge points",
      Academic: [
        "Policy documents",
        "Diagnosis and treatment guidelines",
        "Medical news",
        "Comprehensive dynamic",
        "Classical prescriptions for study and application",
        "Recommended classics",
      ],
    },
  },
  // 名词解释
  search: {
    title: "Noun Definition",
    itemBot: "Derived from",
    shouqi: "retract",
    zhankai: "Expand",
    synonym: "synonym",
    hypernym: "hypernym",
    hyponym: "hyponym",
    Related: "Related words",
    search: "Search criteria",
    Publication: "Publication year",
    shujuku: "Current Search Database",
    mydataTop: "Found",
    mydataBot:
      "entries. If you would like access to additional database privileges, please click on",
    or: "or",
    huiyuan: "Membership Recharge",
    kefu: "contact customer service",
  },
  // 普通检索结果
  searchNav: {
    shujuku: "Database",
    facetName: "Classification",
    shouqi: "retract",
    zhankai: "Expand",
    Loading: "Loading...",
    no: "No data",
    year: "Publication year",
    chaodai: "dynasty",
    Author: "Author",
    list: [
      {
        title: "Entry",
      },
      {
        title: "Bibliography",
      },
      {
        title: "Knowledge",
      },
      {
        title: "Audiovisual",
      },
    ],
  },
  // 条目组件
  clauses: {
    Select: "Select",
    Please: "Please select entries for export",
    all: "Select all",
    Export: "Export",
    research: "Precise research",
    year: "Publication year",
    Ascending: "Ascending order",
    Descending: "Descending order",
    itemBot: "Derived from",
    shouqi: "retract",
    zhankai: "Expand",
    no: "No data",
    gengduo: "More",
    Word: "Word network",
    Knowledge: "Knowledge discovery",
    Trend: "Trend chart of book academic",
    Prime: "Prime",
    quanbu: "All (excluding modern)",
  },
  // 登录
  login: {
    xian: "Please log in first",
    Login: "Login",
    text: "Please enter your phone number/email",
    password: "Please enter your password",
    phone: "Please enter your phone number",
    yanzheng: "Please enter the verification code",
    fasong: "send verification code",
    verificationA: "Login with verification code",
    zhanghao: "password login",
    register: "Go to register",
    Other: "Other login methods",
    mima: "password login",
    awsile: "Forgot your password",
    ketai: "Retrieve by phone",
    email: "Retrieve by email",
    number: "Phone number",
    duanxin: "Please enter the verification sms verification code",
    huoqu: "Get sms verification code",
    tuxing: "Image verification code",
    image: "Please enter the image verification code",
    Please: "Please enter your email address",
    verification: "Please enter the email verification code",
    code: "Verification code",
    next: "Next",
  },
  // 注册
  Register: {
    phone: "Mobile registration",
    email: "Email registration",
    Username: "Username：",
    Usernametext: "Please enter username",
    nametext:
      "1-12 characters, can use Chinese, English, numbers or combination. Cannot be modified after registration.",
    ketai: "Phone number：",
    ketaitext: "Please enter your phone number",
    emailNumber: "Email：",
    emailNumbername: "Please enter your email",
    password: "Password：",
    tishipassword: "Please set a login password of 6-20 characters",
    tishipasswordB: "Please set a login password of 6-20 characters",
    tishipasswordC: "Please set a login password of 6-20 characters",
    tuxing: "Image verification code：",
    yanzheng: "Verification code：",
    queren: "Confirm password：",
    querentext: "Please re-enter your password",
    jigou: "Institution：",
    jigoutext: "Optional, please enter the institution you belong to.",
    jieshou: "I have read and accepted the",
    xieyi: "Registration Agreement",
    zhuce: "Register",
    qita: "Other registration method：",
    emailErr: "The email address has already been registered",
    UsernameErr: "The username has already been registered",
    ketaiErr: "The mobile phone number has already been registered",
    phoneErr: "Please enter your phone number",
    Err: "Please enter your email",
  },
  // 数据可视化
  Visualization: {
    title: "visualization",
    erji: "Return to previous level",
    home: "Home page",
    navList: [
      "Word network",
      "Knowledge discovery",
      "Trend chart of book academic",
    ],
    navs: ["Relationship graph", "Tree diagram"],
  },
  // 提示
  Tips: {
    title: "Tips",
    vip: "You are not a member yet, please upgrade to a member first",
    go: "Top Up Now",
    cancelText: "Think again",
    yes: "OK",
    shujuku: "No database permission is available",
    kong: "The account or password cannot be empty",
    number: "The phone number with the international code, such as France 33.",
  },
  // 修改密码
  Changepassword: {
    title: "Change password",
    new: "New password",
    newText: "Please enter your new password",
    newTwo: "Confirm new password",
    newTwoText: "Please enter your password again",
    ok: "OK",
  },
  ...enLocale,
};
